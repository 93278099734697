import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/www/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { PostsByMonthChart } from '../components/posts-by-month-chart';
import { PostsByDayChart } from '../components/posts-by-day-chart';
import { WordsByMonthChart } from '../components/words-by-month-chart';
import { WordCountChart } from '../components/word-count-chart';
import { TagPercentChart } from '../components/tag-percent-chart';
import { ReactionsByAmount } from '../components/reactions-by-amount';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Divider = makeShortcode("Divider");
const Box = makeShortcode("Box");
const Grid = makeShortcode("Grid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`dashboard`}</h1>
    <p>{`Here are the totals, counts and other statistics calculated from posts, reactions and tags used around my blog`}</p>
    <Divider mdxType="Divider" />
    <Box sx={{
      mb: 5
    }} mdxType="Box">
  <ReactionsByAmount mdxType="ReactionsByAmount" />
    </Box>
    <h2>{`tags by usage`}</h2>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      gridGap: 3,
      mb: 5
    }} mdxType="Grid">
      <TagPercentChart year={0} color={'primary'} mdxType="TagPercentChart" />
      <TagPercentChart year={-1} color={'secondary'} mdxType="TagPercentChart" />
    </Grid>
    <h2>{`posts by day`}</h2>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      gridGap: 3,
      mb: 5
    }} mdxType="Grid">
      <PostsByDayChart config={{
        year: 0,
        color: 'primary'
      }} mdxType="PostsByDayChart" />
      <PostsByDayChart config={{
        year: -1,
        color: 'secondary'
      }} mdxType="PostsByDayChart" />
    </Grid>
    <h2>{`posts by month`}</h2>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      gridGap: 3,
      mb: 5
    }} mdxType="Grid">
      <PostsByMonthChart config={{
        year: 0,
        color: 'primary'
      }} mdxType="PostsByMonthChart" />
      <PostsByMonthChart config={{
        year: -1,
        color: 'secondary'
      }} mdxType="PostsByMonthChart" />
    </Grid>
    <h2>{`words by month`}</h2>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      gridGap: 3,
      mb: 5
    }} mdxType="Grid">
      <WordsByMonthChart config={{
        year: 0,
        color: 'primary'
      }} mdxType="WordsByMonthChart" />
      <WordsByMonthChart config={{
        year: -1,
        color: 'secondary'
      }} mdxType="WordsByMonthChart" />
    </Grid>
    <h2>{`word count statistics`}</h2>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      gridGap: 3,
      mb: 5
    }} mdxType="Grid">
      <WordCountChart title="Average word count" dimension="words" config={{
        year: 0,
        color: 'primary'
      }} mdxType="WordCountChart" />
      <WordCountChart title="Average word count" dimension="words" config={{
        year: -1,
        color: 'secondary'
      }} mdxType="WordCountChart" />
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      